// src/App.js
import React from 'react';
import './App.css';
import SvgViewer from './components/SvgViewer';

const svgList = [...Array(420)].map((_, index) => `/svgs/${index + 1}.svg`);

function App() {
  const handleSvgClick = (svg) => {
    // Handle the click event, e.g., display the SVG code
    console.log('SVG Clicked:', svg);
  };

  return (
    <div className="App">
      <h1>o r p h a n z</h1>
      {/* <h3>friendz for fosterz</h3> */}
      <p>fosterz friendz forever.</p>
      <p>free. fair. fun.</p>
      <p>select an image for instructionz.</p>
      <p>endz when the curse is cleanzed or everyone getz adopted.</p>
      <SvgViewer svgList={svgList} onSvgClick={handleSvgClick} />
    </div>
  );
}

export default App;
